<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g
      id="Groupe_3987"
      data-name="Groupe 3987"
      transform="translate(-1219 -676)"
    >
      <g
        id="Ellipse_1"
        data-name="Ellipse 1"
        transform="translate(1219 676)"
        fill="#fff"
        stroke="#e05012"
        stroke-width="2"
      >
        <circle cx="20" cy="20" r="20" stroke="none" />
        <circle cx="20" cy="20" r="19" fill="none" />
      </g>
      <g id="Groupe_3904" data-name="Groupe 3904" transform="translate(-4 -86)">
        <line
          id="Ligne_5"
          data-name="Ligne 5"
          y2="24"
          transform="translate(1255 782) rotate(90)"
          fill="none"
          stroke="#e05012"
          stroke-linecap="round"
          stroke-width="2"
        />
      </g>
    </g>
  </svg>
</template>
